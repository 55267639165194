import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import MyTooltip from 'components/Tooltip';

import { getProjectModuleByCode } from 'services/api/MyApiUtil';
import GenericActionDisabledTrialPeriodExpired from './TooltipContent/GenericActionDisabledTrialPeriodExpired';
import { useSelector } from 'react-redux';

/**
 * Returns the data freshness status with a colored circle
 *
 * @param {string} color grey|success|warning
 * @returns {*}
 */
const Badge = ({ color }) => {
  return <i className={`zmdi zmdi-circle jr-fs-sm text-${color} mr-2`} />;
};

/**
 *
 * @param {string} title
 * @returns {*}
 */
const DisabledAction = ({ title }) => {
  return (
    <MyTooltip title={<GenericActionDisabledTrialPeriodExpired />}>
      <span>
        <Button disabled={true}>
          {title}
          <HelpIcon fontSize={'small'} className={'ml-1'} />
        </Button>
      </span>
    </MyTooltip>
  );
};

const LogHasDataBadge = props => {
  const { isActiveSubscription } = useSelector(({ auth }) => auth);
  const [data, setData] = useState({});

  const refresh = () => {
    getProjectModuleByCode(props.id, 'log_analyzer').then(result => {
      setData(result);
    });
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nowDate = moment();
  const periodStopDate = moment(data.periodStop);
  const remainingDays = periodStopDate.diff(nowDate, 'days');

  return (
    <div align={'center'}>
      {false === data.hasData ? (
        <div>
          <Badge color={'grey'} />
          <IntlMessages id="bundles.project.global.noData" />

          <p className={'mb-0'}>
            {!isActiveSubscription ? (
              <DisabledAction title={<IntlMessages id="bundles.project.action.sendLogs" />} />
            ) : (
              <Link to={'/log_analyzer/configure?projectId=' + props.id}>
                <IntlMessages id="bundles.project.action.sendLogs" />
              </Link>
            )}
          </p>
        </div>
      ) : (
        <span>
          {0 === remainingDays ? (
            <span>
              <Badge color={'success'} />
              <IntlMessages id="bundles.project.global.upToDate" />

              <p className={'mb-0'}>
                {!isActiveSubscription ? (
                  <DisabledAction title={<IntlMessages id="bundles.project.action.seeLogs" />} />
                ) : (
                  <Link to={`/log_analyzer/explorer?projectId=${props.id}&schema=logs`}>
                    <IntlMessages id="bundles.project.action.seeLogs" />
                  </Link>
                )}
              </p>
            </span>
          ) : (
            <span>
              <Badge color={'warning'} />
              <IntlMessages id="bundles.project.global.noRecentData" values={{ remainingDays: Math.abs(remainingDays) }} />

              <p className={'mb-0'}>
                {!isActiveSubscription ? (
                  <DisabledAction title={<IntlMessages id="bundles.project.action.sendLogs" />} />
                ) : (
                  <Link to={'/log_analyzer/configure?projectId=' + props.id}>
                    <IntlMessages id="bundles.project.action.sendLogs" />
                  </Link>
                )}
              </p>

              <p className={'mb-0'}>
                {!isActiveSubscription ? (
                  <DisabledAction title={<IntlMessages id="bundles.project.action.seeLogs" />} />
                ) : (
                  <Link to={`/log_analyzer/explorer?projectId=${props.id}&schema=logs`}>
                    <IntlMessages id="bundles.project.action.seeLogs" />
                  </Link>
                )}
              </p>
            </span>
          )}
        </span>
      )}
    </div>
  );
};

export default LogHasDataBadge;
