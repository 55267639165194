import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Button, CircularProgress } from '@material-ui/core';

import ChartBar from 'components/Chart/ChartBar';
import ChartPie from 'components/Chart/ChartPie';
import Number from '../components/ChartType/Number';
import AppTable from './ChartType/AppTable';
import ChordDiagram from '../components/ChartType/ChordDiagram';
import StatusCodeIntro from './ChartType/StatusCodeIntro';

import { SCHEMAS } from 'constants/dataExplorer/schema';
import { getRandomColor, getColorByStatusCode, getThemeColor } from 'util/ChartUtil';
import { handleGetLogs, getMetric, exportCsv, closeExportResult } from '../../logAnalyzer/LogsExplorer/actions';

import ExportResultModal from '../../logAnalyzer/LogsExplorer/components/ExportResultModal';

const TIMEOUT_MS = 30000;

const Question = props => {
  const location = useLocation();

  const { count, csvData, rows, isLoading, metric, showExportResult, exportResult, exportStatus } = useSelector(
    ({ logsExplorer }) => logsExplorer,
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [error, setError] = useState(false);

  const ts = Date.now();
  const [show, setShow] = useState(false);

  const [timeDimensions, setTimeDimensions] = useState({
    dateRange: [null, null],
  });

  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(location.search);

  let interval = useRef(null);

  // get schema name
  let schemaName = props.question.query.table;

  useEffect(() => {
    const startDate = urlParams.get('startDate');
    const endDate = urlParams.get('endDate');

    if (SCHEMAS[schemaName].hasTimeDimension) {
      if (startDate && endDate) {
        setTimeDimensions({
          dimension: SCHEMAS[schemaName].timeDimensionField,
          granularity: null,
          dateRange: [startDate, endDate],
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.get('startDate'), urlParams.get('endDate')]);

  /**
   * Triggered on component mount.
   * If the chart concerns the crawl, then add a filter to retrieve data from the last crawl.
   */
  useEffect(() => {
    if ('crawled_pages' === schemaName) {
      //
      setShow(true);
    } else {
      setShow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Triggered on component mount.
   * Start the interval which will check if the timeout to load the data has been reached.
   */
  useEffect(() => {
    interval.current = setInterval(handleCheckTimeout, TIMEOUT_MS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    if ('metric' === props.question.query.type) {
      let crawlId = null;
      if (props.question.query.hiddenFilters) {
        crawlId = props.question.query.hiddenFilters[0]['values'][0];
      }

      dispatch(getMetric(props.question.project, crawlId, props.question.query.metricName, props.question.query.labels));

      return;
    }

    let options = props.question.query;
    options['id'] = props.question['@id'];
    options['projectId'] = props.question.project;

    let filters = [...props.question.query.filters];

    if (props.globalFilters && props.globalFilters.length > 0) {
      filters = [].concat(filters, props.globalFilters);
    } else {
      filters = props.question.query.filters;
    }

    let loadData = true;

    if (props.globalFilters && props.globalFilters.length === 0) {
      if (urlParams.has('filters')) {
        const filtersObjFromQs = JSON.parse(urlParams.get('filters'));

        if (filtersObjFromQs.length > 0) {
          loadData = false;
        }
      }
    }

    /**
     * if a time dimension is required with this schema,
     * and if this information is empty, do not execute the request.
     */
    if (SCHEMAS[schemaName].hasTimeDimension) {
      if (!timeDimensions.dateRange[0] || !timeDimensions.dateRange[1]) {
        loadData = false;
      }
    }

    /**
     * if the request is already in progress, do not execute duplicate request.
     */
    if (isLoading.hasOwnProperty(props.question['@id'])) {
      if (true === isLoading[props.question['@id']]) {
        loadData = false;
      }
    }

    if (loadData) {
      dispatch(handleGetLogs(options, filters, page, rowsPerPage, timeDimensions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ts, props.globalFilters, timeDimensions]);

  /**
   * Triggered on component unmount.
   * Stop the interval.
   */
  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const handleExportCsv = () => {
    let options = props.question.query;
    options['id'] = props.question['@id'];
    options['projectId'] = props.question.project;

    let filters = [...props.question.query.filters];

    if (props.globalFilters && props.globalFilters.length > 0) {
      filters = [].concat(filters, props.globalFilters);
    } else {
      filters = props.question.query.filters;
    }

    dispatch(exportCsv(options, filters, timeDimensions));
  };

  const handleReload = () => {
    // start the interval
    interval.current = setInterval(handleCheckTimeout, TIMEOUT_MS);
    // hide error block
    setError(false);

    let options = props.question.query;
    options['id'] = props.question['@id'];
    options['projectId'] = props.question.project;

    let filters = [...props.question.query.filters];

    if (props.globalFilters && props.globalFilters.length > 0) {
      filters = [].concat(filters, props.globalFilters);
    } else {
      filters = props.question.query.filters;
    }

    dispatch(handleGetLogs(options, filters, page, rowsPerPage, timeDimensions));
  };

  const handleChangePage = (event, newPage) => {
    // start the interval
    interval.current = setInterval(handleCheckTimeout, TIMEOUT_MS);
    // hide error block
    setError(false);

    let options = props.question.query;
    options['id'] = props.question['@id'];
    options['projectId'] = props.question.project;

    let filters = [...props.question.query.filters];

    if (props.globalFilters && props.globalFilters.length > 0) {
      filters = [].concat(filters, props.globalFilters);
    } else {
      filters = props.question.query.filters;
    }

    setPage(newPage);

    dispatch(handleGetLogs(options, filters, newPage, rowsPerPage, timeDimensions));
  };

  const handleChangeRowsPerPage = event => {
    // start the interval
    interval.current = setInterval(handleCheckTimeout, TIMEOUT_MS);
    // hide error block
    setError(false);

    let options = props.question.query;
    options['id'] = props.question['@id'];
    options['projectId'] = props.question.project;

    let filters = [...props.question.query.filters];

    if (props.globalFilters && props.globalFilters.length > 0) {
      filters = [].concat(filters, props.globalFilters);
    } else {
      filters = props.question.query.filters;
    }

    setRowsPerPage(parseInt(event.target.value));
    setPage(0);

    dispatch(handleGetLogs(options, filters, 0, parseInt(event.target.value), timeDimensions));
  };

  const handleCheckTimeout = () => {
    console.log('check timeout!');
    if (isLoading.hasOwnProperty(props.question['@id'])) {
      if (true === isLoading[props.question['@id']]) {
        setError(true);
      }
    }

    clearInterval(interval.current);
  };

  if (!show) {
    return '';
  }

  let colorFunction = getRandomColor;

  if (props.question.visualization.colorFunction) {
    switch (props.question.visualization.colorFunction) {
      case 'getThemeColor':
        colorFunction = getThemeColor;
        break;
      case 'getColorByStatusCode':
        colorFunction = getColorByStatusCode;
        break;
      default:
        colorFunction = getRandomColor;
    }
  }

  const components = {
    bar: ChartBar,
    table: AppTable,
    pie: ChartPie,
    number: Number,
    chordDiagram: ChordDiagram,
    // customs
    StatusCodeIntro: StatusCodeIntro,
  };

  var MyComponent = components[props.question.visualization.type];

  let crawlId = null;

  if ('metric' === props.question.query.type) {
    if (props.question.query.hiddenFilters) {
      crawlId = props.question.query.hiddenFilters[0]['values'][0];
    }
  }

  const metricId =
    'metric' === props.question.query.type
      ? `${props.question.project}.${crawlId}.${props.question.query.metricName}.${props.question.query.labels}`
      : props.question['@id'];

  if (error) {
    return (
      <Box color="text.secondary" p={2}>
        <Box mb={2} align={'center'}>
          An error has occurred
        </Box>
        <Box align={'center'}>
          <Button type={'button'} color="primary" variant={'outlined'} onClick={handleReload}>
            Try again
          </Button>
        </Box>
      </Box>
    );
  }

  if (isLoading.hasOwnProperty(metricId)) {
    if (true === isLoading[metricId]) {
      return (
        <Box color="text.secondary" justifyContent="center" p={2}>
          <CircularProgress size={16} />
          <Box component="span" ml={2}>
            Loading...
          </Box>
        </Box>
      );
    }
  }

  return (
    <React.Fragment>
      <MyComponent
        projectId={props.question.project}
        type={props.question.query.type || null}
        metricId={metricId}
        metricLabelName={props.question.query.metricLabelName || null}
        id={props.question['@id']}
        schemaName={schemaName}
        table={props.question.query.table}
        dimensions={props.question.query.dimensions}
        dimensionsFunctions={props.question.query.dimensionsFunctions}
        timeDimensions={timeDimensions}
        measures={props.question.query.measures}
        filters={
          props.globalFilters ? [...props.question.query.filters, ...props.globalFilters] : props.question.query.filters
        }
        hiddenFilters={props.question.query.hiddenFilters || []}
        groupBy={props.question.query.groupBy || []}
        aggregationFilters={props.question.query.aggregationFilters}
        order={props.question.query.order}
        orderBy={props.question.query.orderBy}
        ungrouped={props.question.query.ungrouped}
        colorChoice={colorFunction}
        ts={ts}
        // data
        count={count}
        csvData={csvData}
        rows={rows}
        metric={metric}
        // pagination
        page={page}
        rowsPerPage={rowsPerPage}
        // visualization
        visualization={props.question.visualization}
        // options
        autoRefresh={props.autoRefresh}
        rowsPerPageOptions={props.rowsPerPageOptions}
        showExportAction={props.showExportAction}
        // handlers
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangeSort={props.handleChangeSort}
        handleExportCsv={handleExportCsv}
      />

      <ExportResultModal
        open={showExportResult}
        status={exportStatus}
        data={exportResult}
        handleClose={() => dispatch(closeExportResult())}
      />
    </React.Fragment>
  );
};

export default Question;
