import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IntlMessages from '@jumbo/utils/IntlMessages';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import RefreshIcon from '@material-ui/icons/Refresh';

import AppDateRangePicker from 'components/Dialog/AppDateRangePicker';
import ChooseCheckboxes from 'components/Drawer/ChooseCheckboxes';
import FilteringContainer from 'bundles/logAnalyzer/LogsFilters/components/FilteringContainer';
import ChooseFilters from 'bundles/logAnalyzer/LogsFilters/components/Drawer/ChooseFilters';
import ChooseMeasures from 'components/Drawer/ChooseMeasures';

import { SCHEMAS } from 'constants/dataExplorer/schema';

import ChooseVisualization from 'components/Drawer/ChooseVisualization';
import Question from 'bundles/dashboard/components/Question';
import { useSelector } from 'react-redux';
import ActiveFiltersAndAddFilterButton from '../../LogsFilters/components/ActiveFiltersAndAddFilterButton';
import { useColumnsSelector } from '../../../columnsSelector/hooks';

const DEFAULT_AGGREGATION_FILTERS = [];
const DEFAULT_DIMENSIONS_FUNCTIONS = [];
const DEFAULT_GROUP_BY = [];
const DEFAULT_MEASURES = [];

const LogsExplorerIndex = props => {
  const urlParams = new URLSearchParams(props.location.search);

  const schemaName = urlParams.get('schema');
  const schema = SCHEMAS[schemaName].fields;

  return (
    <FilteringContainer schema={schema}>
      <LogsExplorerIndexContainer {...props} />
    </FilteringContainer>
  );
};

const LogsExplorerIndexContainer = props => {
  const history = useHistory();
  const { setBreadcrumb } = useContext(AppContext);
  const { project } = useSelector(({ projectSelector }) => projectSelector);
  const urlParams = new URLSearchParams(props.location.search);
  const schemaName = urlParams.get('schema');

  const [ts, setTs] = useState(null);
  const [dialog, setDialog] = useState({
    filter: false,
    measure: false,
    columns: false,
    visualization: false,
  });

  // columns selector
  const [
    openColumnsSelector,
    columnsSelected,
    handleOpenColumnsSelector,
    handleCancelColumnsSelector,
    handleSubmitColumnsSelector,
  ] = useColumnsSelector(SCHEMAS[schemaName].fields);

  // measuring
  // ---------
  // groupBy
  const [groupBy, setGroupBy] = useState(urlParams.has('groupBy') ? JSON.parse(urlParams.get('groupBy')) : DEFAULT_GROUP_BY);
  // measures
  const [measures, setMeasures] = useState(
    urlParams.has('measures') ? JSON.parse(urlParams.get('measures')) : DEFAULT_MEASURES,
  );
  // aggregationFilters
  const aggregationFilters = urlParams.has('aggregationFilters')
    ? JSON.parse(urlParams.get('aggregationFilters'))
    : DEFAULT_AGGREGATION_FILTERS;

  // ungrouped
  const [ungrouped, setUngrouped] = useState(urlParams.has('ungrouped') ? parseInt(urlParams.get('ungrouped')) : 1);
  // dimensionsFunctions
  const [dimensionsFunctions, setDimensionsFunctions] = useState(DEFAULT_DIMENSIONS_FUNCTIONS);
  // sorting
  const [orderBy, setOrderBy] = useState(urlParams.has('orderBy') ? urlParams.get('orderBy') : SCHEMAS[schemaName].orderBy);
  const [order, setOrder] = useState(urlParams.has('order') ? urlParams.get('order') : SCHEMAS[schemaName].order);

  // auto refresh
  const [autoRefresh, setAutoRefresh] = useState(false);
  let autoRefreshInterval = null;

  let schema = SCHEMAS[schemaName].fields;

  // visualization states
  const [visualization, setVisualization] = useState({
    type: 'table',
    options: {},
  });

  useEffect(() => {
    setBreadcrumb([{ text: <IntlMessages id="bundles.LogsExplorer.routes.index.title" /> }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // auto refresh methods
  // --------------------

  const forceRefresh = () => {
    setTs(Date.now());
  };

  const toggleAutoRefresh = () => {
    if (autoRefresh) {
      setAutoRefresh(false);

      // stop auto refresh
      clearInterval(autoRefreshInterval);
    } else {
      setAutoRefresh(true);

      // start auto refresh
      autoRefreshInterval = setInterval(forceRefresh, 5000);
    }
  };

  // dialog/drawer methods
  // --------------

  const handleOpenDialog = name => {
    setDialog(prevState => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleCloseDialog = name => {
    setDialog(prevState => ({
      ...prevState,
      [name]: false,
    }));
  };

  // sorting methods
  // ---------------

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);

    if (!orderBy) {
      urlParams.delete('orderBy');
    } else {
      urlParams.set('orderBy', orderBy);
    }

    if (!order) {
      urlParams.delete('order');
    } else {
      urlParams.set('order', order);
    }

    history.replace({
      pathname: props.location.pathname,
      search: '?' + urlParams.toString(),
    });
  }, [orderBy, order]);

  const handleChangeSort = dimension => {
    const isAsc = orderBy === dimension && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(dimension);
    setTs(Date.now());
  };

  // measure methods
  // ---------------

  const handleAddMeasure = measure => {
    let newMeasures = measures;

    if (newMeasures.indexOf(measure) === -1) {
      newMeasures.push(measure);
    }

    setUngrouped(1);

    if (newMeasures.length > 0) {
      setUngrouped(0);
    }

    setTs(Date.now());
    setGroupBy([]);
    setDimensionsFunctions([]);
    setMeasures(newMeasures);
    setOrder(null);
    setOrderBy(null);
  };

  const handleDeleteMeasure = measure => {
    const index = measures.indexOf(measure);
    let newMeasures = measures;

    if (index !== -1) {
      newMeasures.splice(index, 1);
    }

    setMeasures(newMeasures);
    setTs(Date.now());

    if (newMeasures.length > 0) {
      setUngrouped(0);
    } else {
      setGroupBy([]);
      setDimensionsFunctions([]);
      setUngrouped(1);
      setOrderBy(SCHEMAS[schemaName].orderBy);
      setOrder(SCHEMAS[schemaName].order);
    }
  };

  const handleAddGroupBy = (dimension, extraObj = null) => {
    let newGroupBy = groupBy;
    let newDimensionsFunctions = dimensionsFunctions;

    if (newGroupBy.indexOf(dimension) === -1) {
      newGroupBy.push(dimension);
      newDimensionsFunctions.push(extraObj);
    } else {
      const index = newGroupBy.indexOf(dimension);
      newDimensionsFunctions[index] = extraObj;
    }

    setGroupBy(newGroupBy);
    setDimensionsFunctions(newDimensionsFunctions);
    setTs(Date.now());
  };

  const handleDeleteGroupBy = dimension => {
    const index = groupBy.indexOf(dimension);
    let newGroupBy = groupBy;
    let newDimensionsFunctions = dimensionsFunctions;

    if (index !== -1) {
      newGroupBy.splice(index, 1);
      newDimensionsFunctions.splice(index, 1);
    }

    setGroupBy(newGroupBy);
    setDimensionsFunctions(newDimensionsFunctions);
    setTs(Date.now());
  };

  // visualization methods
  // ---------------------

  const handleChangeVisualization = (type, options) => {
    console.log('handleChangeVisualization');
    let visualizationNew = visualization;

    visualizationNew.type = type;

    if (options) {
      visualizationNew.options = options;
    }

    console.log('visualizationNew', visualizationNew);
    setVisualization(visualizationNew);
    setTs(Date.now());
  };

  useEffect(() => {
    setTs(Date.now());
    // eslint-disable-next-line
    },
    [ columnsSelected ]
  );

  const onDateChange = () => {
    //
  };

  if (false === props.loading) {
    console.log('props.loading === false');
    return null;
  }

  if (!project || !project.id) {
    return null;
  }

  return (
    <div className="app-wrapper">
      <Box display="flex" flexDirection="row" justifyContent={'flex-start'} alignItems={'center'} style={{ float: 'left' }}>
        <ActiveFiltersAndAddFilterButton
          filters={props.filters}
          handleDeleteFilter={props.handleDeleteFilter}
          handleEditFilter={props.handleEditFilter}
          handleOpenFilteringDialog={props.handleOpenFilteringDialog}
        />

        <ChooseFilters
          projectId={project.id}
          name={'filter'}
          title={<IntlMessages id="bundles.LogsFilters.components.CodeFilter.action.filter.title" />}
          open={props.filtersOpen}
          schema={schema}
          schemaName={schemaName}
          addFilter={props.addFilter}
          addFilterSchema={props.addFilterSchema}
          editedFilter={props.editedFilter}
          startDate={urlParams.get('startDate') || ''}
          endDate={urlParams.get('endDate') || ''}
          filters={props.filters}
          hiddenFilters={props.hiddenFilters}
          handleCancel={props.handleCloseFilteringDialog}
          handleAddFilter={props.handleAddFilter}
          handleCancelAddFilter={props.handleCancelAddFilter}
          handleSaveFilter={props.handleSaveFilter}
        />

        <Box mr={1}>
          <ChooseCheckboxes
            title={<IntlMessages id="generic.action.columns.title" />}
            open={openColumnsSelector}
            schema={schema}
            values={columnsSelected}
            handleCancel={handleCancelColumnsSelector}
            handleOk={handleSubmitColumnsSelector}
          />

          <Tooltip title={<IntlMessages id={'generic.action.columns.open.tooltip'} />}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpenColumnsSelector();
              }}>
              <IntlMessages id="generic.action.columns.btn" />
            </Button>
          </Tooltip>
        </Box>

        <Box mr={1}>
          <ChooseMeasures
            name={'measure'}
            title={<IntlMessages id="generic.action.measure.title" />}
            open={dialog.measure}
            measuresActives={measures}
            dimensionsFunctions={dimensionsFunctions}
            schema={schema}
            values={groupBy}
            handleAddMeasure={handleAddMeasure}
            handleDeleteMeasure={handleDeleteMeasure}
            handleAddGroupBy={handleAddGroupBy}
            handleDeleteGroupBy={handleDeleteGroupBy}
            handleCancel={handleCloseDialog}
          />

          <Tooltip title={<IntlMessages id={'generic.action.measure.open.tooltip'} />}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpenDialog('measure');
              }}>
              <IntlMessages id="generic.action.measure.btn" />
            </Button>
          </Tooltip>
        </Box>

        <Box mr={1}>
          <ChooseVisualization
            name={'visualization'}
            title={'generic.action.visualization.title'}
            open={dialog.visualization}
            visualization={visualization}
            handleCancel={handleCloseDialog}
            handleChange={handleChangeVisualization}
            dimensions={measures.length === 0 ? columnsSelected : groupBy}
            dimensionsFunctions={dimensionsFunctions}
            measures={measures}
            filters={props.filters}
            hiddenFilters={props.hiddenFilters}
            ungrouped={ungrouped}
          />

          <Tooltip title={<IntlMessages id={'generic.action.visualization.open.tooltip'} />}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpenDialog('visualization');
              }}>
              <IntlMessages id="generic.action.visualization.btn" />
            </Button>
          </Tooltip>
        </Box>

        <Box mr={1}>
          {true === autoRefresh ? (
            <IconButton aria-label="refresh">
              <RefreshIcon color={'disabled'} fontSize="large" />
            </IconButton>
          ) : (
            <Tooltip title={<IntlMessages id={'generic.action.refresh.title'} />}>
              <IconButton aria-label="refresh" onClick={forceRefresh}>
                <RefreshIcon color={'primary'} fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Box mr={1}>
          {true === autoRefresh ? (
            <Tooltip title={<IntlMessages id={'generic.action.autoRefreshStop.title'} />}>
              <IconButton aria-label="play" onClick={toggleAutoRefresh}>
                <PauseIcon color={'primary'} fontSize="large" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={<IntlMessages id={'generic.action.autoRefreshStart.title'} />}>
              <IconButton aria-label="play" onClick={toggleAutoRefresh}>
                <PlayArrowIcon color={'primary'} fontSize="large" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      {SCHEMAS[schemaName].hasTimeDimension && <AppDateRangePicker projectId={project.id} onDateChange={onDateChange} />}

      <Grid container alignItems="flex-start" item spacing={3}>
        <Grid item xs={12}>
          <Question
            ts={ts}
            globalFilters={props.filters}
            autoRefresh={autoRefresh}
            rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
            showExportAction={true}
            handleChangeSort={handleChangeSort}
            question={{
              '@id': 'data_explorer',
              project: project.id,
              query: {
                projectId: project.id,
                table: SCHEMAS[schemaName].table,
                dimensions: measures.length === 0 ? columnsSelected : groupBy,
                dimensionsFunctions: dimensionsFunctions,
                measures: measures,
                filters: props.filters,
                hiddenFilters: props.hiddenFilters,
                aggregationFilters: aggregationFilters,
                order: order,
                orderBy: orderBy,
                ungrouped: ungrouped,
              },
              visualization: visualization,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LogsExplorerIndex;
